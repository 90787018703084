.selectedItem {
	background-color: #BAE7FF;

}

.ant-table-thead > tr > th:hover {
            background-color: #00b4ed;
 }

.ant-table-tbody > tr >th:hover{
	background: #00b4ed !important;
}

.ant-table-tbody > tr >td:hover{
    background: #FFCC00 !important;
}

{/*  这是背景颜色，先去除

 .ant-table-tbody > tr > td {
     background: #00b4ed !important;
 }


*/}

.site-button-ghost-wrapper {
    padding: 8px 8px 10px 8px;
    background: rgb(190, 200, 200);
    text-align: right;
}


