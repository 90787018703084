
/*-- reset --*/

blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}

/*-- start editing from here --*/

/*-- end reset --*/
body {
font-family:Verdana;  
	/* Text_Me_One, sans-serif; */
	background: #004071;
	background: -webkit-linear-gradient(to bottom right, #004071 50%, #004071 50%);
	background: linear-gradient(to bottom right , #004071 50%, #004071 50%);
	background-size:cover;
	background-attachment: fixed;
}
body,html,.wrapper { height:100%;}
/*-- main --*/ 
::-webkit-input-placeholder {
   color: #fff;  
   font-weight:100;
} 
:-moz-placeholder { /* Firefox 18- */
   color: #fff; 
} 
::-moz-placeholder {  /* Firefox 19+ */
   color: #fff;  
} 
:-ms-input-placeholder {  
   color: #fff;  
} 
/*-- //responsive-design --*/
